const Cou_States = [
  {
    state: "Andhra Pradesh",
  },

  {
    state: "Arunachal Pradesh",
  },
  {
    state: "Assam",
  },

  {
    state: "Bihar",
  },
  {
    state: "Chhattisgarh",
  },
  {
    state: "Delhi",
  },
  {
    state: "Goa",
  },
  {
    state: "Gujarat",
  },

  {
    state: "Haryana",
  },
  {
    state: "Himachal Pradesh",
  },

  {
    state: "Jammu and Kashmir",
  },
  {
    state: "Jharkhand",
  },

  {
    state: "Karnataka",
  },
  {
    state: "Kerala",
  },

  {
    state: "Madhya Pradesh",
  },
  {
    state: "Maharashtra",
  },

  {
    state: "Manipur",
  },
  //
  {
    state: "Meghalaya",
  },

  {
    state: "Mizoram",
  },
  {
    state: "Nagaland",
  },

  {
    state: "Odisha",
  },
  {
    state: "Punjab",
  },

  {
    state: "Rajasthan",
  },
  {
    state: "Sikkim",
  },

  {
    state: "Tamil Nadu",
  },
  {
    state: "Telangana",
  },

  {
    state: "Tripura",
  },
  {
    state: "Uttar Pradesh",
  },

  {
    state: "Uttarakhand",
  },
  {
    state: "West Bengal",
  },
];

export default Cou_States;

// Andhra Pradesh
// Arunachal Pradesh
// Assam
// Bihar
// Chhattisgarh
// Goa
// Gujarat
// Haryana
// Himachal Pradesh
// Jammu and Kashmir
// Jharkhand
// Karnataka
// Kerala
// Madhya Pradesh
// Maharashtra
// Manipur
// Meghalaya
// Mizoram
// Nagaland
// Odisha
// Punjab
// Rajasthan
// Sikkim
// Tamil Nadu
// Telangana
// Tripura
// Uttar Pradesh
// Uttarakhand
// West Bengal
//
