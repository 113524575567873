import React from "react";
import "./opportunities.css";

import DashNavBar from "../Components/DashNavBar";
import { Contexts } from "../Context/Contexts";
import NewOpporDialog from "../Components/NewOpporDialog";

export default function Opportunities() {
  // const { newLeadDialogBox, setNewLeadDialogBox } = useContext(Contexts);
  const { OpportunitiesDialogBox, OpportunitiesSectionClass } =
    React.useContext(Contexts);

  const [opportunitiesSectionClass, setOpportunitiesSectionClass] = OpportunitiesSectionClass;
  const [newOpportunityDialogBox, setNewOpportunityDialogBox] =
    OpportunitiesDialogBox;

  return (
    <>
      <NewOpporDialog Class={newOpportunityDialogBox} />

      <section className={opportunitiesSectionClass}>
        <DashNavBar Heading="Opportunities Management" />
        <div className="opportunitiesDivSearchCURD">
          <div className="searchBarDiv">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              id="searchBaropportunities"
              placeholder="Search From Opportunities"
              className="searchBaropportunities"
              type="text"
            />
          </div>
          <div className="opportunitiesCURDdiv">
            <button
              onClick={() => {
                if (
                  newOpportunityDialogBox === "NewOpportunityDialogBox_Close"
                ) {
                  setNewOpportunityDialogBox("NewOpportunityDialogBox_Open");
                  setOpportunitiesSectionClass("opportunitiesSection_disabled");
                  console.log(newOpportunityDialogBox);
                  return;
                } else {
                  setNewOpportunityDialogBox("NewOpportunityDialogBox_Close");
                  console.log(newOpportunityDialogBox);
                  setOpportunitiesSectionClass("opportunitiesSection");
                  return;
                }
              }}
            >
              New
            </button>
            <button>Delete</button>
          </div>
        </div>

        <div className="tableDiv">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Machine Requirement</th>
                <th>Machine Size</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
