import React from "react";
import "./dashboard.css";
import DashNavBar from "../Components/DashNavBar";

export default function Dashboard() {
  return (
    <>
      <section className="DashBoardSection">
        <DashNavBar Heading="Dashboard" />{" "}
      </section>
    </>
  );
}
