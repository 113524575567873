import React, { useEffect, useState } from "react";
import "./tasks.css";
import DashNavBar from "../Components/DashNavBar";
import { Link } from "react-router-dom";
import axios from "axios";

export default function Tasks() {
  const [leads, setLeads] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [taskID, setTaskID] = useState();
  const [taskID_Update, setTaskID_update] = useState();
  const [taskComment_Update, setTaskComment_Update] = useState("Enter Comment");

  // console.log(tasks);

  // let newTasks = tasks.sort((a, b) => a.dateNew - b.dateNew);

  // function onChanging() {
  // setTaskStatus_Filter(taskFilter_Update.value);
  // let taskFilter_Update = document.getElementById("_id_leadFilterUpdate");
  // taskFilter_Update.addEventListener("keydown", (event) => {
  //   if (event.key === "Enter") {
  //   }
  // });
  // }

  // console.log(newTasks);

  // Tasks Variables

  const [dueDate, setDueDate] = useState();
  const [taskcomment, setComment] = useState("");
  const [taskStatus, setTaskStatus] = useState("Working");
  const [taskResult, setTaskResult] = useState("");
  const [taskResult_place_holder, setTaskResult_place_holder] = useState("");

  // End of Tasks Variables

  // Task Filter Variables

  const [filterDialog_Class, setFilterDialogClass] = useState(
    "filterTaskDivision_disabled"
  );

  const [dueDate_filter, setDueDate_Filter] = useState();
  // let date = new Date();
  const [taskStatus_filter, setTaskStatus_Filter] = useState("");

  // End of Task Filter Variables

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://api.x360marketing.org/api/leadsData"
        );
        // const response = await axios.get("http://127.0.0.1:3001/api/leadsData");
        const response_tasks = await axios.get(
          "https://api.x360marketing.org/api/tasksData_sorted"
          // "http://127.0.0.1:3001/api/tasksData_sorted"
        );
        setLeads(response.data);
        setTasks(response_tasks.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  const [taskLeadName, setTaskLeadName] = useState("Select Task");
  return (
    <>
      <section className="TasksSection">
        <DashNavBar Heading="Tasks Management" />

        <section className="TasksDisplaySection">
          {" "}
          <div className="taskDetailsDiv_Left_TasksManagement">
            <div className="taskNavDisplay">
              <div className="searchBarTasks">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  placeholder="Search From Tasks"
                  className="searchBarTasks"
                  type="text"
                  onChange={(e) => { }}
                />
              </div>
              <i
                onClick={() => {
                  if (filterDialog_Class === "filterTaskDivision") {
                    setFilterDialogClass("filterTaskDivision_disabled");
                  } else {
                    setFilterDialogClass("filterTaskDivision");
                  }
                }}
                className="fa-solid fa-filter fa-lg"
              ></i>
              <div className={filterDialog_Class}>
                <h1>Filter Tasks</h1>
                <label htmlFor="">Filter By Due Date</label>
                <input
                  id="_id_taskFilter_DueDate"
                  onChange={(e) => {
                    setDueDate_Filter(e.target.value);
                  }}
                  type="date"
                />
                <label htmlFor="">Filter By Lead Status</label>
                <input
                  onChange={(e) => { }}
                  id="_id_leadFilterUpdate_status"
                  placeholder="Enter Lead Status"
                  type="text"
                />
                <div className="buttonDiv_Filter">
                  <button
                    onClick={() => {
                      let taskFilter_Update_status = document.getElementById(
                        "_id_leadFilterUpdate_status"
                      ).value;
                      let taskFilter_Update_dueDate = document.getElementById(
                        "_id_taskFilter_DueDate"
                      ).value;

                      let filteredDate = new Date(
                        taskFilter_Update_dueDate
                      ).toDateString();

                      setDueDate_Filter(filteredDate);
                      setTaskStatus_Filter(taskFilter_Update_status);
                      setFilterDialogClass("filterTaskDivision_disabled");
                    }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>

            <div className="tasksTable">
              <table>
                <thead>
                  <tr>
                    <th>Lead Name</th>
                    <th>Mobile</th>
                    <th>Status</th>
                    <th>Due Date</th>
                    <th>Comment</th>
                    {/* <th>Results</th> */}
                  </tr>
                </thead>
                <tbody>

                  {tasks
                    .filter((task) =>
                      task.taskStatus
                        .toLocaleLowerCase()
                        .includes(taskStatus_filter.toLocaleLowerCase())
                    )
                    .map((task) => {
                      let leadName = leads.find(
                        (lead) => task.taskID === lead._id
                      );

                      let dateNew = new Date(task.dateNew).toDateString();
                      let todaysDate = new Date().toDateString();

                      let dueDate_Filter;

                      if (
                        dueDate_filter === "" ||
                        dueDate_filter === null ||
                        dueDate_filter === undefined
                      ) {
                        dueDate_Filter = new Date().toDateString();
                      } else {
                        dueDate_Filter = new Date(
                          dueDate_filter
                        ).toDateString();
                      }

                      if (dueDate_Filter === dateNew) {
                        if (todaysDate === dateNew) {
                          dateNew = "Today";
                        }
                        return (
                          <>
                            <tr>
                              <td>
                                <Link to={`/leads/${leadName._id}`}>
                                  {leadName.leadName}
                                </Link>
                              </td>
                              <td>
                                <Link
                                  onClick={() => {
                                    setTaskLeadName(leadName.leadName);
                                    setTaskID(task.taskID);
                                    setTaskID_update(task._id);
                                    setTaskComment_Update(task.taskcomment);
                                    setTaskResult_place_holder(task.taskResult);
                                  }}
                                >
                                  +91 {leadName.mobile}
                                </Link>
                              </td>
                              <td>{task.taskStatus}</td>
                              <td><Link>{dateNew}</Link></td>
                              <td>{task.taskcomment}</td>
                            </tr>
                          </>
                        );
                      } else {
                        return null;
                      }
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="createFollowUpTaskDiv_TaskManagement">
            <h1>Create Follow Up Tasks</h1>
            <span>
              <label htmlFor="">Lead Name</label>
              <input disabled={true} value={taskLeadName} type="text" />
            </span>
            <span>
              <label htmlFor="">Due Date</label>
              <input
                onChange={(e) => {
                  setDueDate(e.target.value);
                }}
                type="date"
              />
            </span>
            <div className="ckeckBoxDiv">
              <label htmlFor="">Status {"(Completed)"}</label>
              <input
                onChange={() => {
                  if (
                    document.getElementById("checkBox_status").checked === true
                  ) {
                    setTaskStatus("Completed");
                  } else {
                    setTaskStatus("Working");
                  }
                }}
                type="checkbox"
                name="checkBox_status"
                id="checkBox_status"
              />
            </div>
            <span>
              <label htmlFor="">Comment</label>
              <input
                onChange={(e) => {
                  setComment(e.target.value);
                }}
                placeholder={taskComment_Update}
                type="text"
              />
            </span>
            <span>
              <label htmlFor="">Result</label>
              <input
                onChange={(e) => {
                  setTaskResult(e.target.value);
                }}
                placeholder={taskResult_place_holder}
                type="text"
              />
            </span>
            <div className="btnDiv_addTaskDiv">
              <button
                onClick={async () => {
                  if (
                    taskID === undefined ||
                    dueDate === "" ||
                    taskcomment === ""
                  ) {
                    alert("Something Went Wrong");
                    return;
                  } else {
                    let dateNew = new Date(dueDate).toDateString();
                    dateNew.toLocaleString();
                    try {
                      await axios.post(
                        "https://api.x360marketing.org/api/newTask",
                        {
                          // await axios.post("http://127.0.0.1:3001/api/newTask", {
                          taskID,
                          dateNew,
                          taskStatus,
                          taskcomment,
                        }
                      );
                      alert("Follow Up Task Created");
                      setTaskID("");
                      window.location.reload();
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  }
                }}
                className="saveTaskButton"
              >
                Create
              </button>
              <button
                onClick={async () => {
                  if (taskStatus === "" || taskResult === "") {
                    alert("Please Fill Details");
                  } else {
                    try {
                      // console.log(taskID_Update);
                      await axios.post(
                        "https://api.x360marketing.org/api/updateTask",
                        {
                          // await axios.post("http://127.0.0.1:3001/api/updateTask", {
                          _id: taskID_Update,
                          taskStatus,
                          taskResult,
                        }
                      );

                      alert("Task Updated");

                      window.location.reload();
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    }
                  }

                  // console.log(taskResult);
                  // console.log("Hitted");
                }}
              >
                Update
              </button>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
