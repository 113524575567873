import React, { useState } from "react";
import "./signInPage.css";
import bgImage from "../Images/loginFormImage.jpg";
import logo from "../Assets/x360Logo_White.png";
import { Link } from "react-router-dom";
export default function SignInPage() {
  const [userName, setUserName] = useState();
  const [userPass, setPassword] = useState();

  // eslint-disable-next-line
  // eslint-disable-next-line

  // const userLogIn_Name = localStorage.getItem("userName");
  // const userLogIn_Pass = localStorage.getItem("userPass");

  return (
    <>
      <section className="SignInPage_MainSection">
        <div className="loginForm">
          {" "}
          <div className="leftDiv_imgDiv_SignInSection">
            <img src={bgImage} alt="Login Form x360Marketing CRM" />
          </div>
          <div className="rightDiv_formDiv_SignInSection">
            <div className="icon_Div_Logo">
              <img src={logo} alt="x360 Marketing Logo Icon" />
              <h1>Welcome to x360 CRM Solution For You Business</h1>
              <p>Please Login With Your Credentials for Start Adventure</p>
            </div>
            <div className="formDiv">
              <div className="inputFieldDiv">
                <label htmlFor="">Username</label>
                <input
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  placeholder="Enter Username"
                  type="text"
                />
              </div>

              <div className="inputFieldDiv">
                <label htmlFor="">Password</label>
                <input
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  placeholder="Enter Password"
                  type="password"
                />
              </div>

              <div className="rememberMe_Div">
                <span>
                  {" "}
                  <input type="checkbox" />
                  <label htmlFor="">Remember Me</label>
                </span>

                <Link>Forgot Password?</Link>
              </div>

              <div className="buttonDiv_form">
                <button
                  onClick={() => {
                    var now = new Date().getTime();

                    if (
                      userName === "jitendra@machmart" &&
                      userPass === "machmart@0224"
                    ) {
                      localStorage.setItem("userName", userName);
                      alert("Login Successfull");
                      localStorage.setItem("setupTime", now);

                      localStorage.setItem("userPass", userPass);
                      window.location.href = "/dashboard";
                    } else {
                      alert("Invalid Data");
                    }
                  }}
                >
                  {" "}
                  Login
                </button>
              </div>
              <span className="newToPlatForm_span">
                New to Our Platform? <Link>Create an Account</Link>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
