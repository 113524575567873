import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import NavigationBar from "./Components/NavigationBar";
import Dashboard from "./Pages/Dashboard";
import Leads from "./Pages/Leads";
import Opportunities from "./Pages/Opportunities";
import Contacts from "./Pages/Contacts";
import LeadDisplay from "./Pages/LeadDisplay";
import Tasks from "./Pages/Tasks";
import { Contexts } from "./Context/Contexts";
import { useState } from "react";
import SignInPage from "./Pages/SignInPage";

function App() {
  const [newLeadDialogBox, setNewLeadDialogBox] = useState(
    "NewLeadDialogBox_Close"
  );
  const [newOpportunityDialogBox, setNewOpportunityDialogBox] = useState(
    "NewLeadDialogBox_Close"
  );
  const [newcontactDialogBox, setNewcontactDialogBox] = useState(
    "NewcontactDialogBox_Close"
  );
  const [leadsSectionClass, setLeadsSectionClass] = useState("LeadsSection");
  const [opportunitiesSectionClass, setOpportunitiesSectionClass] = useState(
    "opportunitiesSection"
  );
  const [contactsSectionClass, setcontactsSectionClass] =
    useState("contactsSection");

  const [userName_con, setUserName_con] = useState("");
  const [userPass_con, setUserPass_con] = useState("");

  const userLogIn_Name = localStorage.getItem("userName");
  const userLogIn_Pass = localStorage.getItem("userPass");

  if (
    userLogIn_Name === "jitendra@machmart" &&
    userLogIn_Pass === "machmart@0224"
  ) {
    var hours = 12;
    var now = new Date().getTime();
    console.log(now);
    var setupTime = localStorage.getItem("setupTime");

    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.clear();
      window.location.href = "/";
      return;
    }

    return (
      <>
        <BrowserRouter>
          <section className="App">
            <Contexts.Provider
              value={{
                LeadsDialogBoxClass: [newLeadDialogBox, setNewLeadDialogBox],
                LeadsSectionClass: [leadsSectionClass, setLeadsSectionClass],
                OpportunitiesDialogBox: [
                  newOpportunityDialogBox,
                  setNewOpportunityDialogBox,
                ],
                OpportunitiesSectionClass: [
                  opportunitiesSectionClass,
                  setOpportunitiesSectionClass,
                ],
                ContactsDialogBox: [
                  newcontactDialogBox,
                  setNewcontactDialogBox,
                ],
                ContactsSectionClass: [
                  contactsSectionClass,
                  setcontactsSectionClass,
                ],
              }}
            >
              <NavigationBar />
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/leads" element={<Leads />} />
                <Route path="/leads/:_id" element={<LeadDisplay />} />
                <Route path="/opportunities" element={<Opportunities />} />
                <Route path="/contacts" element={<Contacts />} />
                <Route path="/tasks" element={<Tasks />} />
              </Routes>
            </Contexts.Provider>
          </section>
        </BrowserRouter>
      </>
    );
  } else {
    return (
      <>
        <BrowserRouter>
          <section className="App">
            <Contexts.Provider
              value={{
                Username_User: [userName_con, setUserName_con],
                UserPass_User: [userPass_con, setUserPass_con],
              }}
            >
              <Routes>
                <Route path="/" element={<SignInPage />} />
              </Routes>
            </Contexts.Provider>
          </section>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
