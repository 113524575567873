import React from "react";
import "./newContactDialogBox.css";
import { Contexts } from "../Context/Contexts";

export default function NewContactDialogBox(props) {
  // const { newLeadDialogBox, setNewLeadDialogBox } = useContext(Contexts);

  const { ContactsSectionClass, ContactsDialogBox } =
    React.useContext(Contexts);

  // eslint-disable-next-line
  const [contactsSectionClass, setcontactsSectionClass] = ContactsSectionClass;

  const [newcontactDialogBox, setNewcontactDialogBox] = ContactsDialogBox;

  console.log(newcontactDialogBox);

  return (
    <>
      <section id="dialogBoxNewcontact" className={props.Class}>
        <div className="newcontactNavDiv">
          <h1>New contact</h1>
          <i
            onClick={() => {
              if (newcontactDialogBox === "NewcontactDialogBox_Close") {
                setNewcontactDialogBox("NewcontactDialogBox_Open");
                setcontactsSectionClass("contactsSection_disabled");
                console.log(newcontactDialogBox);
                return;
              } else {
                setNewcontactDialogBox("NewcontactDialogBox_Close");
                console.log(newcontactDialogBox);
                setcontactsSectionClass("contactsSection");
                return;
              }
            }}
            class="fa-solid fa-xmark fa-lg"
          ></i>
        </div>

        <div className="addcontactInfoDiv">
          <div className="leftDiv_Addcontact">
            <label htmlFor="newcontactName">Name*</label>
            <input
              placeholder="Enter contact Name"
              type="text"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
            <label htmlFor="newcontactName">Mobile</label>
            <input
              placeholder="Enter Mobile"
              type="text"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
            <label htmlFor="newcontactName">What's App</label>
            <input
              placeholder="Enter What's App"
              type="text"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
            <label htmlFor="newcontactName">contact Creation Date</label>
            <input
              placeholder="Enter What's App"
              type="date"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
            <label htmlFor="newcontactName">Machine Requirement</label>
            <input
              placeholder="Picklist"
              type="text"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
            <label htmlFor="newcontactName">Machine Size</label>
            <input
              placeholder="Enter Size"
              type="text"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
            <label htmlFor="newcontactName">contact Status</label>
            <input
              placeholder="Picklist"
              type="text"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
          </div>
          <div className="rightDiv_Addcontact">
            <label htmlFor="newcontactName">City</label>
            <input
              placeholder="Enter City"
              type="text"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
            <label htmlFor="newcontactName">State</label>
            <input
              placeholder="Enter State"
              type="text"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
            <label htmlFor="newcontactName">Country</label>
            <input
              placeholder="Enter Country"
              type="text"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
            <label htmlFor="newcontactName">Pincode</label>
            <input
              placeholder="Enter Pincode"
              type="text"
              className="newcontact_Name"
              id="newcontact_Name"
            />{" "}
          </div>
        </div>
        <div className="addcontactbuttonsDiv">
          <button
            onClick={() => {
              if (newcontactDialogBox === "NewcontactDialogBox_Close") {
                setNewcontactDialogBox("NewcontactDialogBox_Open");
                setcontactsSectionClass("contactsSection_disabled");
                console.log(newcontactDialogBox);
                return;
              } else {
                setNewcontactDialogBox("NewcontactDialogBox_Close");
                console.log(newcontactDialogBox);
                setcontactsSectionClass("contactsSection");
                return;
              }
            }}
            className="CancleButtonClass"
          >
            Cancle
          </button>
          <button className="SaveButtonClass">Save</button>
        </div>
      </section>{" "}
    </>
  );
}
