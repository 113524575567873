import React, { useState } from "react";
import "./navigationBar.css";
import x360Logo from "../Assets/x360Logo.png";

import dashboardNavWhite from "../Assets/dashboardNav_white.png";
import dashboardNavBlack from "../Assets/dashboardNav.png";
import leadsNav from "../Assets/salesNavBlack.png";
import leadsNav_White from "../Assets/salesNavWhite.png";
import opportunitiesNav from "../Assets/leadsNavBlack.png";
import opportunitiesNav_White from "../Assets/leadsNavWhite.png";
import tasksNav from "../Assets/tasksNav.png";
import tasksNav_White from "../Assets/tasksNavWhite.png";
import incentiveNav from "../Assets/incentiveNav.png";
import incentiveNav_White from "../Assets/incentiveNavWhite.png";
import contactsNav from "../Assets/contactsNav.png";
import contactsNav_White from "../Assets/contactsNavWhite.png";
import reportsNav from "../Assets/reportsNav.png";
import reportsNav_White from "../Assets/reportsNavWhite.png";
import whatsAppNav from "../Assets/whatsAppNav.png";
import whatsAppNav_White from "../Assets/whatsAppNavWhite.png";
import integrationNav from "../Assets/integrationNavBlack.png";
import integrationNav_White from "../Assets/integrationNavWhite.png";
import settingsNav from "../Assets/settingsNav.png";
import settingsNav_White from "../Assets/settingsNavWhite.png";
import { Link } from "react-router-dom";

export default function NavigationBar() {
  const [dashboardItemClass, setDashboardItemClass] = useState(
    "dashboardItem_Black"
  );
  const [leadsItemClass, setLeadsItemClass] = useState("leadsItem_White");
  const [opportunitiesItemClass, setOpportunitiesItemClass] = useState(
    "opportunities_White"
  );
  const [tasksItemClass, setTasksItemClass] = useState("tasks_White");
  const [incentiveItemClass, setIncentiveItemClass] =
    useState("incentive_White");
  const [contactItemClass, setContactsItemClass] = useState("contact_White");
  const [reportsItemClass, setReportsItemClass] = useState("reports_White");
  const [whatsAppItemClass, setWhatsAppItemClass] = useState("whatsapp_White");
  const [integrationItemClass, setIntegrationItemClass] =
    useState("integration_White");
  const [settingsItemClass, setSettingsItemClass] = useState("settings_White");
  const [dashboardImg, setDashboardImg] = useState(dashboardNavWhite);
  const [leadsImg, setLeadsImg] = useState(leadsNav);
  const [opportunitiesImg, setOpportunitiesImg] = useState(opportunitiesNav);
  const [tasksImg, setTasksImg] = useState(tasksNav);
  const [incentiveImg, setIncentiveImg] = useState(incentiveNav);
  const [contactImg, setContactImg] = useState(contactsNav);
  const [reportsImg, setReportsImg] = useState(reportsNav);
  const [whatsAppImg, setWhatsAppImg] = useState(whatsAppNav);
  const [integrationImg, setIntegrationImg] = useState(integrationNav);
  const [settingsImg, setSettingsImg] = useState(settingsNav);

  return (
    <>
      <section className="NavigationBar">
        <div className="mainDivision_NavigationBar">
          <div className="logoDiv">
            <img src={x360Logo} alt="x360 Marketing Logo" />
          </div>
          <div className="navbarDiv">
            <ul>
              <Link to={"/dashboard"}>
                {" "}
                <li
                  onClick={() => {
                    setDashboardItemClass("dashboardItem_Black");
                    setLeadsItemClass("leadsItem_White");
                    setOpportunitiesItemClass("opportunities_White");
                    setContactsItemClass("contact_White");
                    setTasksItemClass("tasks_White");
                    setIncentiveItemClass("incentive_White");
                    setReportsItemClass("reports_White");
                    setWhatsAppItemClass("whatsapp_White");
                    setIntegrationItemClass("integration_White");
                    setSettingsItemClass("settings_White");
                    //   Image
                    setDashboardImg(dashboardNavWhite);
                    setLeadsImg(leadsNav);
                    setOpportunitiesImg(opportunitiesNav);
                    setTasksImg(tasksNav);
                    setIncentiveImg(incentiveNav);
                    setContactImg(contactsNav);
                    setReportsImg(reportsNav);
                    setWhatsAppImg(whatsAppNav);
                    setIntegrationImg(integrationNav);
                    setSettingsImg(settingsNav);
                  }}
                  className={dashboardItemClass}
                >
                  <img src={dashboardImg} alt="Dashboard" />
                  Dashboard
                </li>
              </Link>
              <Link to={"/leads"}>
                {" "}
                <li
                  onClick={() => {
                    setLeadsItemClass("leadsItem_Black");
                    setDashboardItemClass("dashboardItem_White");
                    setOpportunitiesItemClass("opportunities_White");
                    setContactsItemClass("contact_White");
                    setTasksItemClass("tasks_White");
                    setIncentiveItemClass("incentive_White");
                    setReportsItemClass("reports_White");
                    setWhatsAppItemClass("whatsapp_White");
                    setIntegrationItemClass("integration_White");
                    setSettingsItemClass("settings_White");
                    //   Image
                    setLeadsImg(leadsNav_White);
                    setDashboardImg(dashboardNavBlack);
                    setOpportunitiesImg(opportunitiesNav);
                    setTasksImg(tasksNav);
                    setIncentiveImg(incentiveNav);
                    setContactImg(contactsNav);
                    setReportsImg(reportsNav);
                    setWhatsAppImg(whatsAppNav);
                    setIntegrationImg(integrationNav);
                    setSettingsImg(settingsNav);
                  }}
                  className={leadsItemClass}
                >
                  <img src={leadsImg} alt="Dashboard" />
                  Leads
                </li>
              </Link>
              <Link to={"/opportunities"}>
                {" "}
                <li
                  onClick={() => {
                    setOpportunitiesItemClass("opportunities_Black");
                    setDashboardItemClass("dashboardItem_White");
                    setLeadsItemClass("leadsItem_White");
                    setContactsItemClass("contact_White");
                    setTasksItemClass("tasks_White");
                    setIncentiveItemClass("incentive_White");
                    setReportsItemClass("reports_White");
                    setWhatsAppItemClass("whatsapp_White");
                    setIntegrationItemClass("integration_White");
                    setSettingsItemClass("settings_White");
                    //   Image
                    setOpportunitiesImg(opportunitiesNav_White);
                    setDashboardImg(dashboardNavBlack);
                    setLeadsImg(leadsNav);
                    setTasksImg(tasksNav);
                    setIncentiveImg(incentiveNav);
                    setContactImg(contactsNav);
                    setReportsImg(reportsNav);
                    setWhatsAppImg(whatsAppNav);
                    setIntegrationImg(integrationNav);
                    setSettingsImg(settingsNav);
                  }}
                  className={opportunitiesItemClass}
                >
                  <img src={opportunitiesImg} alt="Opportunities" />
                  Opportunities
                </li>
              </Link>
              <Link to={"/contacts"}>
                {" "}
                <li
                  onClick={() => {
                    setOpportunitiesItemClass("opportunities_White");
                    setDashboardItemClass("dashboardItem_White");
                    setLeadsItemClass("leadsItem_White");
                    setContactsItemClass("contact_Black");
                    setTasksItemClass("tasks_White");
                    setIncentiveItemClass("incentive_White");
                    setReportsItemClass("reports_White");
                    setWhatsAppItemClass("whatsapp_White");
                    setIntegrationItemClass("integration_White");
                    setSettingsItemClass("settings_White");
                    //   Image
                    setContactImg(contactsNav_White);
                    setOpportunitiesImg(opportunitiesNav);
                    setDashboardImg(dashboardNavBlack);
                    setLeadsImg(leadsNav);
                    setTasksImg(tasksNav);
                    setIncentiveImg(incentiveNav);
                    setReportsImg(reportsNav);
                    setWhatsAppImg(whatsAppNav);
                    setIntegrationImg(integrationNav);
                    setSettingsImg(settingsNav);
                  }}
                  className={contactItemClass}
                >
                  {" "}
                  <img src={contactImg} alt="Contacts" />
                  Contacts
                </li>
              </Link>
              <Link to={"/tasks"}>
                <li
                  onClick={() => {
                    setOpportunitiesItemClass("opportunities_White");
                    setDashboardItemClass("dashboardItem_White");
                    setLeadsItemClass("leadsItem_White");
                    setContactsItemClass("contact_White");
                    setTasksItemClass("tasks_Black");
                    setIncentiveItemClass("incentive_White");
                    setReportsItemClass("reports_White");
                    setWhatsAppItemClass("whatsapp_White");
                    setIntegrationItemClass("integration_White");
                    setSettingsItemClass("settings_White");
                    //   Image
                    setContactImg(contactsNav);
                    setOpportunitiesImg(opportunitiesNav);
                    setDashboardImg(dashboardNavBlack);
                    setLeadsImg(leadsNav);
                    setTasksImg(tasksNav_White);
                    setIncentiveImg(incentiveNav);
                    setReportsImg(reportsNav);
                    setWhatsAppImg(whatsAppNav);
                    setIntegrationImg(integrationNav);
                    setSettingsImg(settingsNav);
                  }}
                  className={tasksItemClass}
                >
                  {" "}
                  <img src={tasksImg} alt="Tasks" />
                  Tasks
                </li>
              </Link>
              <li
                onClick={() => {
                  setOpportunitiesItemClass("opportunities_White");
                  setDashboardItemClass("dashboardItem_White");
                  setLeadsItemClass("leadsItem_White");
                  setContactsItemClass("contact_White");
                  setTasksItemClass("tasks_White");
                  setIncentiveItemClass("incentive_Black");
                  setReportsItemClass("reports_White");
                  setWhatsAppItemClass("whatsapp_White");
                  setIntegrationItemClass("integration_White");
                  setSettingsItemClass("settings_White");
                  //   Image
                  setContactImg(contactsNav);
                  setOpportunitiesImg(opportunitiesNav);
                  setDashboardImg(dashboardNavBlack);
                  setLeadsImg(leadsNav);
                  setTasksImg(tasksNav);
                  setIncentiveImg(incentiveNav_White);
                  setReportsImg(reportsNav);
                  setWhatsAppImg(whatsAppNav);
                  setIntegrationImg(integrationNav);
                  setSettingsImg(settingsNav);
                }}
                className={incentiveItemClass}
              >
                <img src={incentiveImg} alt="Tasks" />
                Incentives
              </li>
              <li
                onClick={() => {
                  setOpportunitiesItemClass("opportunities_White");
                  setDashboardItemClass("dashboardItem_White");
                  setLeadsItemClass("leadsItem_White");
                  setContactsItemClass("contact_White");
                  setTasksItemClass("tasks_White");
                  setIncentiveItemClass("incentive_White");
                  setReportsItemClass("reports_Black");
                  setWhatsAppItemClass("whatsapp_White");
                  setIntegrationItemClass("integration_White");
                  setSettingsItemClass("settings_White");
                  //   Image
                  setContactImg(contactsNav);
                  setOpportunitiesImg(opportunitiesNav);
                  setDashboardImg(dashboardNavBlack);
                  setLeadsImg(leadsNav);
                  setTasksImg(tasksNav);
                  setIncentiveImg(incentiveNav);
                  setReportsImg(reportsNav_White);
                  setWhatsAppImg(whatsAppNav);
                  setIntegrationImg(integrationNav);
                  setSettingsImg(settingsNav);
                }}
                className={reportsItemClass}
              >
                <img src={reportsImg} alt="" />
                Reports
              </li>
              <li
                onClick={() => {
                  setOpportunitiesItemClass("opportunities_White");
                  setDashboardItemClass("dashboardItem_White");
                  setLeadsItemClass("leadsItem_White");
                  setContactsItemClass("contact_White");
                  setTasksItemClass("tasks_White");
                  setIncentiveItemClass("incentive_White");
                  setReportsItemClass("reports_White");
                  setWhatsAppItemClass("whatsapp_Black");
                  setIntegrationItemClass("integration_White");
                  setSettingsItemClass("settings_White");
                  //   Image
                  setContactImg(contactsNav);
                  setOpportunitiesImg(opportunitiesNav);
                  setDashboardImg(dashboardNavBlack);
                  setLeadsImg(leadsNav);
                  setTasksImg(tasksNav);
                  setIncentiveImg(incentiveNav);
                  setReportsImg(reportsNav);
                  setWhatsAppImg(whatsAppNav_White);
                  setIntegrationImg(integrationNav);
                  setSettingsImg(settingsNav);
                }}
                className={whatsAppItemClass}
              >
                <img src={whatsAppImg} alt="" />
                What's App API
              </li>
              <li
                onClick={() => {
                  setOpportunitiesItemClass("opportunities_White");
                  setDashboardItemClass("dashboardItem_White");
                  setLeadsItemClass("leadsItem_White");
                  setContactsItemClass("contact_White");
                  setTasksItemClass("tasks_White");
                  setIncentiveItemClass("incentive_White");
                  setReportsItemClass("reports_White");
                  setWhatsAppItemClass("whatsapp_White");
                  setIntegrationItemClass("integration_Black");
                  setSettingsItemClass("settings_White");
                  //   Image
                  setContactImg(contactsNav);
                  setOpportunitiesImg(opportunitiesNav);
                  setDashboardImg(dashboardNavBlack);
                  setLeadsImg(leadsNav);
                  setTasksImg(tasksNav);
                  setIncentiveImg(incentiveNav);
                  setReportsImg(reportsNav);
                  setWhatsAppImg(whatsAppNav);
                  setIntegrationImg(integrationNav_White);
                  setSettingsImg(settingsNav);
                }}
                className={integrationItemClass}
              >
                <img src={integrationImg} alt="" />
                Integration
              </li>
              <li
                onClick={() => {
                  setOpportunitiesItemClass("opportunities_White");
                  setDashboardItemClass("dashboardItem_White");
                  setLeadsItemClass("leadsItem_White");
                  setContactsItemClass("contact_White");
                  setTasksItemClass("tasks_White");
                  setIncentiveItemClass("incentive_White");
                  setReportsItemClass("reports_White");
                  setWhatsAppItemClass("whatsapp_White");
                  setIntegrationItemClass("integration_White");
                  setSettingsItemClass("settings_Black");
                  //   Image
                  setContactImg(contactsNav);
                  setOpportunitiesImg(opportunitiesNav);
                  setDashboardImg(dashboardNavBlack);
                  setLeadsImg(leadsNav);
                  setTasksImg(tasksNav);
                  setIncentiveImg(incentiveNav);
                  setReportsImg(reportsNav);
                  setWhatsAppImg(whatsAppNav);
                  setIntegrationImg(integrationNav);
                  setSettingsImg(settingsNav_White);
                }}
                className={settingsItemClass}
              >
                {" "}
                <img src={settingsImg} alt="Settings" />
                Settings
              </li>
            </ul>
          </div>
          <div className="copyrightndtcDiv">Terms and Conditions</div>
        </div>
      </section>
    </>
  );
}
