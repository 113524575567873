import React from "react";
import "./newOpportunities.css";
import { Contexts } from "../Context/Contexts";

export default function NewOpporDialog(props) {
  // const { newLeadDialogBox, setNewLeadDialogBox } = useContext(Contexts);

  const { OpportunitiesSectionClass, OpportunitiesDialogBox } =
    React.useContext(Contexts);

  // eslint-disable-next-line
  const [opportunitiesSectionClass, setOpportunitiesSectionClass] =
    OpportunitiesSectionClass;

  const [newOpportunityDialogBox, setNewOpportunityDialogBox] =
    OpportunitiesDialogBox;

  return (
    <>
      <section id="dialogBoxNewOpportunity" className={props.Class}>
        <div className="newOpportunityNavDiv">
          <h1>New Opportunity</h1>
          <i
            onClick={() => {
              if (newOpportunityDialogBox === "NewOpportunityDialogBox_Close") {
                setNewOpportunityDialogBox("NewOpportunityDialogBox_Open");
                setOpportunitiesSectionClass("opportunitiesSection_disabled");
                console.log(newOpportunityDialogBox);
                return;
              } else {
                setNewOpportunityDialogBox("NewOpportunityDialogBox_Close");
                console.log(newOpportunityDialogBox);
                setOpportunitiesSectionClass("opportunitiesSection");
                return;
              }
            }}
            class="fa-solid fa-xmark fa-lg"
          ></i>
        </div>

        <div className="addOpportunityInfoDiv">
          <div className="leftDiv_AddOpportunity">
            <label htmlFor="newOpportunityName">Name*</label>
            <input
              placeholder="Enter Opportunity Name"
              type="text"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
            <label htmlFor="newOpportunityName">Mobile</label>
            <input
              placeholder="Enter Mobile"
              type="text"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
            <label htmlFor="newOpportunityName">What's App</label>
            <input
              placeholder="Enter What's App"
              type="text"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
            <label htmlFor="newOpportunityName">
              Opportunity Creation Date
            </label>
            <input
              placeholder="Enter What's App"
              type="date"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
            <label htmlFor="newOpportunityName">Machine Requirement</label>
            <input
              placeholder="Picklist"
              type="text"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
            <label htmlFor="newOpportunityName">Machine Size</label>
            <input
              placeholder="Enter Size"
              type="text"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
            <label htmlFor="newOpportunityName">Opportunity Status</label>
            <input
              placeholder="Picklist"
              type="text"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
          </div>
          <div className="rightDiv_AddOpportunity">
            <label htmlFor="newOpportunityName">City</label>
            <input
              placeholder="Enter City"
              type="text"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
            <label htmlFor="newOpportunityName">State</label>
            <input
              placeholder="Enter State"
              type="text"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
            <label htmlFor="newOpportunityName">Country</label>
            <input
              placeholder="Enter Country"
              type="text"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
            <label htmlFor="newOpportunityName">Pincode</label>
            <input
              placeholder="Enter Pincode"
              type="text"
              className="newOpportunity_Name"
              id="newOpportunity_Name"
            />{" "}
          </div>
        </div>
        <div className="addOpportunitybuttonsDiv">
          <button
            onClick={() => {
              if (newOpportunityDialogBox === "NewOpportunityDialogBox_Close") {
                setNewOpportunityDialogBox("NewOpportunityDialogBox_Open");
                setOpportunitiesSectionClass("opportunitiesSection_disabled");
                console.log(newOpportunityDialogBox);
                return;
              } else {
                setNewOpportunityDialogBox("NewOpportunityDialogBox_Close");
                console.log(newOpportunityDialogBox);
                setOpportunitiesSectionClass("opportunitiesSection");
                return;
              }
            }}
            className="CancleButtonClass"
          >
            Cancle
          </button>
          <button onClick={()=>{



          }} className="SaveButtonClass">Save</button>
        </div>
      </section>
    </>
  );
}
