import React from "react";
import "./dashnavbar.css";
export default function DashNavBar(props) {

  return (
    <>
      <section className="NavBar_Dash">
        <div className="mainDivision_DashNavbar">
          <div className="headingDiv_NavbarDash">
            {" "}
            <h1>{props.Heading}</h1>
          </div>

          <div className="iconsDiv_NavbarDash">
            <span>
              <i className="fa-solid fa-bell fa-lg"></i>
            </span>
            <span>
              <i
                onClick={() => {
                  localStorage.removeItem("userName");
                  localStorage.removeItem("userPass");
                  alert("Log Out Successfull");
                  window.location.href = "/";
                }}
                className="fa-solid fa-user-gear fa-lg"
              ></i>
            </span>
          </div>
        </div>
      </section>
    </>
  );
}
