import React, { useEffect, useState } from "react";
import "./leadsDisplay.css";
import "../Components/newLeadDialogBox.css";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import DashNavBar from "../Components/DashNavBar";

export default function LeadDisplay() {
  const [leadStatusClass, setLeadstatusClass] = useState(
    "listDropDown_disabled"
  );
  const [leadStatusNew, setLeadStatusNew] = useState("None");

  const [leads, setLeads] = useState([]);
  const [tasks, setTasks] = useState([]);
  let { _id } = useParams();

  const taskID = _id;

  // const [leadName, setLeadName] = useState("");
  // const [mobile, setMobile] = useState("");
  // const [productSize, setProductSize] = useState("");
  // const [city, setCity] = useState("");
  // const [state, setState] = useState("");
  // const [country, setCountry] = useState("");
  // const [pincode, setPincode] = useState("");
  // const [notes, setNotes] = useState("");

  // Tasks Variables

  const [dueDate, setDueDate] = useState();
  const [taskcomment, setComment] = useState("");

  // End of Tasks Variables

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://api.x360marketing.org/api/leadsData"
          // "http://127.0.0.1:3001/api/leadsData"
        );
        const response_tasks = await axios.get(
          "https://api.x360marketing.org/api/tasksData"
          // "http://127.0.0.1:3001/api/tasksData"
        );
        setLeads(response.data);
        setTasks(response_tasks.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <section className="LeadDisplayPage">
        <DashNavBar Heading="Leads Management" />
        {leads.map((lead) => {
          // setLeadStatusNew(lead.leadStatus);
          if (lead._id === _id) {
            let dateNew = new Date(lead.date).toDateString();

            return (
              <>
                <section className="LeadDisplaySection">
                  <div className="leadsDetailsDiv_LeadDisplay">
                    <div className="navigationBarLeadsDetails">
                      <ul>
                        <li>Details</li>
                      </ul>
                    </div>
                    <div className="leadDetailsDiv_show">
                      <div className="leftDiv_leadDet">
                        {" "}
                        <span>
                          {" "}
                          <label htmlFor="leadNName">Name</label>
                          <input
                            onChange={(e) => {
                              // setLeadName(e.target.value);
                            }}
                            type="text"
                            placeholder={lead.leadName}
                          />
                        </span>
                        <span>
                          {" "}
                          <label htmlFor="leadNName">Mobile</label>
                          <input
                            onChange={(e) => {
                              // setMobile(e.target.value);
                            }}
                            type="text"
                            placeholder={lead.mobile}
                          />
                        </span>
                        <span>
                          {" "}
                          <label htmlFor="leadNName">Lead Status</label>{" "}
                          {/* <input type="text" placeholder={leadStatusNew} /> */}
                          <div
                            onClick={() => {
                              if (leadStatusClass === "listDropDown") {
                                setLeadstatusClass("listDropDown_disabled");
                              } else {
                                setLeadstatusClass("listDropDown");
                              }
                            }}
                            className="leadSource_dropDown"
                          >
                            <span>
                              {leadStatusNew}{" "}
                              <i className="fa-solid fa-caret-down"></i>
                            </span>
                            <div className={leadStatusClass}>
                              <ul>
                                <li
                                  onClick={() => {
                                    setLeadStatusNew("Working - Not Contacted");
                                  }}
                                >
                                  Working - Not Contacted
                                </li>
                                <li
                                  onClick={() => {
                                    setLeadStatusNew("Open - Contacted");
                                  }}
                                >
                                  Open - Contacted
                                </li>
                                <li
                                  onClick={() => {
                                    setLeadStatusNew("Closed - Converted");
                                  }}
                                >
                                  Closed - Converted
                                </li>
                                <li
                                  onClick={() => {
                                    setLeadStatusNew("Closed - Not Converted");
                                  }}
                                >
                                  Closed - Not Converted
                                </li>
                              </ul>
                            </div>
                          </div>
                        </span>
                        <span>
                          {" "}
                          <label htmlFor="leadNName">Creation Date</label>{" "}
                          <input
                            disabled={true}
                            type="text"
                            placeholder={dateNew}
                          />
                        </span>
                        <span>
                          {" "}
                          <label htmlFor="leadNName">
                            Machine Requirement
                          </label>{" "}
                          <input
                            disabled={true}
                            type="text"
                            placeholder={lead.productReq}
                          />
                        </span>
                        <span>
                          {" "}
                          <label htmlFor="leadNName">Machine Size</label>{" "}
                          <input
                            onChange={(e) => {
                              // setProductSize(e.target.value);
                            }}
                            type="text"
                            placeholder={lead.productSize}
                          />
                        </span>
                        <span>
                          {" "}
                          <label htmlFor="leadNName">Lead Source</label>{" "}
                          <input
                            type="text"
                            disabled={true}
                            placeholder={lead.leadSource}
                          />
                        </span>
                      </div>
                      <div className="rightDiv_leadDet">
                        <span>
                          {" "}
                          <label htmlFor="leadNName">City</label>{" "}
                          <input
                            onChange={(e) => {
                              // setCity(e.target.value);
                            }}
                            type="text"
                            placeholder={lead.city}
                          />
                        </span>
                        <span>
                          {" "}
                          <label htmlFor="leadNName">State</label>{" "}
                          <input
                            onChange={(e) => {
                              // setState(e.target.value);
                            }}
                            type="text"
                            placeholder={lead.state}
                          />
                        </span>
                        <span>
                          {" "}
                          <label htmlFor="leadNName">Country</label>{" "}
                          <input
                            onChange={(e) => {
                              // setCountry(e.target.value);
                            }}
                            type="text"
                            placeholder={lead.country}
                          />
                        </span>
                        <span>
                          {" "}
                          <label htmlFor="leadNName">Pincode</label>{" "}
                          <input
                            onChange={(e) => {
                              // setPincode(e.target.value);
                            }}
                            type="text"
                            placeholder={lead.pincode}
                          />
                        </span>
                        <span>
                          {" "}
                          <label htmlFor="leadNName">Notes</label>{" "}
                          <textarea
                            onChange={(e) => {
                              // setNotes(e.target.value);
                            }}
                            type="text"
                            placeholder={lead.notes}
                          />
                        </span>

                        <div className="buttonsDiv_leadsDisplayDiv">
                          <button
                            onClick={async () => {
                              // console.log(leadStatusNew);
                              // console.log(lead._id);
                              try {
                                await axios.post(
                                  "https://api.x360marketing.org/api/updateLead",
                                  // "http://127.0.0.1:3001/api/updateLead",
                                  {
                                    _id: taskID,
                                    leadStatus: leadStatusNew,
                                  }
                                );

                                alert("Lead Status Updated");
                                window.location.href = "/leads";
                              } catch (error) {
                                console.error("Error fetching data:", error);
                              }
                              // window.location.reload();
                            }}
                          >
                            Update
                          </button>
                          <button
                            onClick={async () => {
                              window.confirm("Are You Sure?");

                              let deletePass = prompt(
                                "Please Enter Password",
                                ""
                              );
                              if (deletePass === "Jitendra7769") {
                                try {
                                  await axios.post(
                                    "https://api.x360marketing.org/api/deteleTask",
                                    // "http://127.1.0.0:3001/api/deleteLead",
                                    {
                                      taskID: lead._id,
                                    }
                                  );
                                  const response = await axios.post(
                                    "https://api.x360marketing.org/api/deleteLead",
                                    // "http://127.1.0.0:3001/api/deleteLead",
                                    {
                                      _id: lead._id,
                                    }
                                  );
                                  if (response.data === "Deleted") {
                                    alert("Lead Deleted");
                                    window.location.href = "/leads"; //relative to domain
                                  }
                                } catch (error) {
                                  console.error("Error fetching data:", error);
                                }
                                // alert("Lead Deleted");
                              } else {
                                alert("Password Wrong");
                              }
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="taskAddDiv_LeadDisplay">
                    <div className="headingDiv_TaskAdd">
                      <h1>Add a Task</h1>
                    </div>
                    <div className="addtaskDiv">
                      <span>
                        <label htmlFor="">Due Date</label>
                        <input
                          className="taskInputTags"
                          onChange={(e) => {
                            setDueDate(e.target.value);
                          }}
                          type="date"
                        />
                      </span>
                      <span>
                        <label htmlFor="">Comment</label>
                        <input
                          className="taskInputTags"
                          onChange={(e) => {
                            setComment(e.target.value);
                          }}
                          type="text"
                          placeholder="Enter Comment"
                        />
                      </span>

                      <div className="btnDiv_addTaskDiv">
                        {" "}
                        <button
                          onClick={async () => {
                            let dateNew = new Date(dueDate).toDateString();
                            console.log(dateNew);
                            if (
                              dateNew === "Invalid Date" ||
                              taskcomment === ""
                            ) {
                              alert("Please Fill Details");
                            } else {
                              try {
                                await axios.post(
                                  "https://api.x360marketing.org/api/newTask",
                                  // "http://127.0.0.1:3001/api/newTask",
                                  {
                                    taskID,
                                    dateNew,
                                    taskStatus: "Working",
                                    taskcomment,
                                  }
                                );

                                alert("Task Created");
                                const inputTag =
                                  document.getElementsByClassName(
                                    "taskInputTags"
                                  );

                                inputTag[0].value = "";
                                inputTag[1].value = "";
                              } catch (error) {
                                console.error("Error fetching data:", error);
                              }
                              window.location.reload();
                            }
                          }}
                          className="saveTaskButton"
                        >
                          Create
                        </button>
                      </div>
                    </div>
                    <div className="leadActivityDiv_show">
                      <div className="leadActivities_Heading_Div_showAll">
                        <h1>Lead Activities</h1>
                      </div>

                      {tasks.toReversed().map((task) => {
                        if (task.taskID === _id) {
                          let todaysDate = new Date().toDateString();
                          let newDate = new Date(task.dateNew);
                          newDate = newDate.toDateString();

                          if (todaysDate === newDate) {
                            newDate = "Today";
                          }
                          return (
                            <>
                              <div className="leadActivities_All">
                                <div className="activity_per">
                                  <span>
                                    <Link to={`/${task._id}`}>
                                      <h2>Call {`(${task.taskStatus})`} </h2>
                                    </Link>
                                    <p>Due: {newDate}</p>
                                  </span>
                                  <h3>{task.taskcomment}</h3>
                                </div>
                              </div>
                            </>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                </section>
              </>
            );
          } else {
            return null;
          }
        })}
      </section>
    </>
  );
}
