import React, { useEffect, useState } from "react";
import "./leads.css";
import DashNavBar from "../Components/DashNavBar";
import NewLeadDialog from "../Components/NewLeadDialog";
import { Contexts } from "../Context/Contexts";
import axios from "axios";
import { Link } from "react-router-dom";

export default function Leads() {
  const [leads, setLeads] = useState([]);
  const [leadSearchName, setLeadSearchName] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://api.x360marketing.org/api/leadsData"
        );
        // const response = await axios.get("http://127.0.0.1:3001/api/leadsData");
        setLeads(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  // const { newLeadDialogBox, setNewLeadDialogBox } = useContext(Contexts);
  const { LeadsDialogBoxClass, LeadsSectionClass } = React.useContext(Contexts);

  const [newLeadDialogBox, setNewLeadDialogBox] = LeadsDialogBoxClass;
  const [leadsSectionClass, setLeadsSectionClass] = LeadsSectionClass;

  return (
    <>
      <NewLeadDialog Class={newLeadDialogBox} />
      <section className={leadsSectionClass}>
        <DashNavBar Heading="Leads Management" />
        <div className="leadsDivSearchCURD">
          <div className="searchBarDiv">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              id="searchBarLeads"
              placeholder="Search From Leads"
              className="searchBarLeads"
              type="text"
              onChange={(e) => {
                setLeadSearchName(e.target.value);
                console.log(leadSearchName);
              }}
            />
          </div>
          <div className="leadsCURDdiv">
            {/* <div>Filter Leads</div> */}
            <button
              onClick={() => {
                if (newLeadDialogBox === "NewLeadDialogBox_Close") {
                  setNewLeadDialogBox("NewLeadDialogBox_Open");
                  setLeadsSectionClass("LeadsSection_disabled");
                  console.log(newLeadDialogBox);
                  return;
                } else {
                  setNewLeadDialogBox("NewLeadDialogBox_Close");
                  setLeadsSectionClass("LeadsSection");
                  console.log(newLeadDialogBox);
                  return;
                }
              }}
            >
              New
            </button>
          </div>
        </div>

        <div className="tableDiv">
          <table>
            <thead>
              <tr>
                <th>Serial</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Lead Status</th>
                <th>Creation Date</th>
                <th>Lead Source</th>
                <th>Machine Requirement</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
              </tr>
            </thead>
            <tbody>
              {/* <tr>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr> */}

              {/* {leads.map((lead) => {
                return (
                  <>
                    <tr key={lead._id}>
                      <td>{lead.srNum}</td>
                      <td>
                        <Link to={lead._id}>{lead.leadName}</Link>
                      </td>
                      <td>{lead.mobile}</td>
                      <td>{lead.leadStatus}</td>
                      <td>{lead.productReq}</td>
                      <td>{lead.city}</td>
                      <td>{lead.state}</td>
                      <td>{lead.country}</td>
                    </tr>
                  </>
                );
              })} */}

              {leads
                .filter((lead) =>
                  lead.leadName
                    .toLocaleLowerCase()
                    .includes(leadSearchName.toLocaleLowerCase())
                ).reverse()
                .map((lead) => {
                  let newDate = new Date(lead.date).toDateString();

                  console.log(leads.length);


                  return (
                    <>
                      <tr>
                        <td className="count"></td>
                        <td>
                          <Link to={`/leads/${lead._id}`}>{lead.leadName}</Link>
                        </td>
                        <td><Link>+91 {lead.mobile}</Link></td>
                        <td>{lead.leadStatus}</td>
                        <td><Link>{newDate}</Link></td>
                        <td>{lead.leadSource}</td>
                        <td><Link>{lead.productReq}</Link></td>
                        <td>{lead.city}</td>
                        <td>{lead.state}</td>
                        <td>{lead.country}</td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
