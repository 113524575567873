import React from "react";
import "./contacts.css";

import DashNavBar from "../Components/DashNavBar";
import { Contexts } from "../Context/Contexts";
import NewContactDialogBox from "../Components/NewContactDialogBox";

export default function Contacts() {
  const { ContactsDialogBox, ContactsSectionClass } =
    React.useContext(Contexts);

  const [contactsSectionClass, setcontactsSectionClass] = ContactsSectionClass;
  const [newcontactDialogBox, setNewcontactDialogBox] = ContactsDialogBox;

  return (
    <>
      <NewContactDialogBox Class={newcontactDialogBox} />

      <section className={contactsSectionClass}>
        <DashNavBar Heading="Contacts Management" />
        <div className="contactsDivSearchCURD">
          <div className="searchBarDiv">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              id="searchBarcontacts"
              placeholder="Search From Contacts"
              className="searchBarcontacts"
              type="text"
            />
          </div>
          <div className="contactsCURDdiv">
            <button
              onClick={() => {
                if (newcontactDialogBox === "NewcontactDialogBox_Close") {
                  setNewcontactDialogBox("NewcontactDialogBox_Open");
                  setcontactsSectionClass("contactsSection_disabled");
                  console.log(newcontactDialogBox);
                  return;
                } else {
                  setNewcontactDialogBox("NewcontactDialogBox_Close");
                  console.log(newcontactDialogBox);
                  setcontactsSectionClass("contactsSection");
                  return;
                }
              }}
            >
              New
            </button>
            <button>Delete</button>
          </div>
        </div>

        <div className="tableDiv">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Mobile</th>
                <th>Machine Requirement</th>
                <th>Machine Size</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>01</td>
                <td>Jitendra Jangid</td>
                <td>+91 8503886992</td>
                <td>Combined Planer</td>
                <td>13 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <input
                    type="checkbox"
                    name="checkboxInpur"
                    id="inpurCheckbox"
                  />
                </td>
                <td>02</td>
                <td>Arun Jangid</td>
                <td>+91 8503886992</td>
                <td>Surface Planer</td>
                <td>10 Inch</td>
                <td>Jaipur</td>
                <td>Rajasthan</td>
                <td>India</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
